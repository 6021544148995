/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ModelTags } from '@/components/pages/model-account/my-account-tags/model-account-my-account-tags.interface';
import { ModelAccountState } from '@/store/modelAccount/types'
const namespace: string = 'modelAccount';


export default class ModelAccountMyAccountTagsPage extends Vue {
	@State('modelAccount') modelAccount: ModelAccountState | undefined;
	@Action('updateProfileInformation', {namespace}) updateProfileInformation: any;
	@Getter('getModelSettingsUsername', {namespace}) getModelSettingsUsername: any;
	@Action('getAllTags', {namespace}) getAllTags: any;
	@Getter('getModelSettingsTags', {namespace}) getModelSettingsTags: any;

	profileInformation: ModelTags = {
		tags: []
	}

	tagInputValue: string = '';

	onChange() {
		this.profileInformation.tags.push(this.tagInputValue);
	}

	onSubmit() {
		this.updateProfileInformation({
			tags: this.profileInformation.tags
		})
	}

	mounted() {
		//this.getAllTags();
		this.profileInformation.tags = this.getModelSettingsTags
	}
}
